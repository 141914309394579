<template>
	<div id="app">
		<Tabs></Tabs>
	</div>
</template>

<script>
import Tabs from "@/views/Tabs.vue";
export default {
	name: "App",
	components: {
		Tabs,
	},
};
</script>

<style>
* {
	margin: 0;
	padding: 0;
	border: 0;
}
html,
body {
	width: 100%;
	height: 100%;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	width: 100%;
	height: 100%;
}
</style>
