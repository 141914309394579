<template>
	<div class="tabs_bg">
		<div class="tabs_wrap">
			<div
				class="tab_item jdxb_item"
				@click="jumpUrl('/jdxb')"
				title="节点小宝2.0"
			>
				<div class="tab_item_logo jdxb_logo"></div>
				<div class="tab_item_name jdxb_name">节点小宝</div>
				<div class="tab_item_tips">
					一款<span>特别好用</span>远程管理工具
				</div>
			</div>
			<div
				class="tab_item wwxz_item"
				@click="jumpUrl('/wwxz')"
				title="玩物下载"
			>
				<div class="tab_item_logo wwxz_logo"></div>
				<div class="tab_item_name">玩物下载</div>
			</div>
		</div>
		<div class="foot">
			<font>@2022 版权所有 深圳市玩物科技有限公司</font>
			<a
				class="beian"
				href="http://www.beian.miit.gov.cn"
				target="_blank"
				title="粤ICP备18147482号-1"
				>粤ICP备18147482号-1</a
			>
			<a
				class="gongan"
				target="_blank"
				href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502004455"
				title="粤公网安备 44030502004455号"
				>粤公网安备 44030502004455号</a
			>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		jumpUrl: function (url) {
			window.location.href = url;
		},
	},
};
</script>

<style lang="less" scoped>
.tabs_bg {
	background-image: url("../assets/bg.png");
	background-size: 100% 100%;
	width: 100%;
	height: 100%;
	.tabs_wrap {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -640px;
		margin-top: -360px;
		width: 1280px;
		height: 720px;
		background-image: url("../assets/center_bg.png");
		background-size: 100% 100%;
		.tab_item {
			width: 276px;
			height: 322px;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			.tab_item_logo {
				margin-top: 30px;
				display: inline-block;
				width: 178px;
				height: 178px;
				background-repeat: no-repeat;
			}
			.tab_item_name {
				color: #ffffff;
				font-size: 24px;
				margin-top: 2px;
				font-weight: bolder;
			}
			.tab_item_tips {
				background-color: #3c4b76;
				color: #f5f5f5;
				font-size: 14px;
				height: 36px;
				border-radius: 18px;
				line-height: 36px;
				width: 200px;
				transform-origin: center center;
				margin: 0 auto;
				margin-top: 12px;
				span {
					color: #f2bb4c;
				}
			}
			.jdxb_logo {
				background-image: url("../assets/jdxb_logo5.png");
				background-size: 162px 162px;
				background-position: center center;
				margin-top: 20px;
			}
			.jdxb_logo:hover {
				background-size: 178px 178px;
			}
			.jdxb_name {
				font-size: 24px;
			}
			.wwxz_logo {
				background-image: url("../assets/wwxz_logo.png");
				background-size: 162px 162px;
				background-position: center center;
				margin-top: 20px;
			}
			.wwxz_logo:hover {
				background-size: 178px 178px;
			}
		}
		.jdxb_item {
			margin-left: 324px;
			margin-top: 224px;
		}
		.wwxz_item {
			margin-right: 324px;
			margin-top: 224px;
			float: right;
		}
		.tab_item:hover {
			background-image: url("../assets/logo_hover.png");
			background-size: 100% 100%;
			cursor: pointer;
			.tab_item_logo {
				background-size: 178px 178px;
			}
		}
	}

	.foot {
		position: absolute;
		left: 0;
		bottom: 20px;
		display: block;
		color: #999999;
		text-align: center;
		width: 100%;
		font-size: 12px;
		font {
			margin-right: 10px;
		}
		a {
			color: #999999;
			margin-right: 10px;
		}
		.gongan {
			background-image: url(../assets/ga_icon.png);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: 14px 15px;
			padding-left: 20px;
		}
	}
}
</style>